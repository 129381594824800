<template>
    <div class="">
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot pb-3">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-2">Donations Report</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                      <ol class="breadcrumb float-sm-right">
                      <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                      <li class="breadcrumb-item"><Breadcrumbs/></li>
                      </ol>
                      </div> 
                    </div>
                  </div>
                  <div class="table-responsive mt-3">
                     <div class="data-tablebutn pb-3">
                        <b-button class="btn-primary btn-darkblue btn" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-donation-filter>
                          <i class="fas fa-sliders-h"></i> Filters
                        </b-button>
                        <!-- <b-button class="btn-primary btn-darkblue btn m-0 ml-2" :href="basePath+'api/report/donations/pdf'">
                          <i class="fas fa-file-csv"></i> Export
                        </b-button> -->
                        <b-button class="ml-1 btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                          <i class="fas fa-redo"></i> Reset
                        </b-button>
                        <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                            <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                        </b-button>
                      </div>
                    <table class="table table-sm table-bordered table-hover" id="list_table">
                      <thead class="thead-dark">
                      <tr>
                        <th>Member ID</th>
                        <th>Membership</th>
                        <th>Name</th>
                        <th>Donation Discipline</th>
                        <th>Donation Amount</th>
                        <th>Transaction ID</th>
                        <th>Transaction Date</th>
                      </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <b-modal id="modal-donation-filter" v-bind:hide-footer="true" ref="modal-donation-filter" size="lg"  title="Advanced Search">
        <form ref="form" method="post" @submit.prevent="advancedSearch()">
          <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Member Id</label>
                    <input type="text" id="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Name</label>
                    <input type="text" id="full_name" class="form-control" v-model="searchForm.full_name">
                </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                  <label class="form-label">Membership</label>
                <select id="membership_id" name="membership_id" class="form-control" v-model="searchForm.membership_id">
                  <option value="">Select</option>
                  <option v-for="(id, val) in membershipData" :value="val" :key="val">{{ id }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6" v-if="getSiteProps('general.donation_report_filter_discipline')">
              <div class="form-group">
                <label class="form-label">Donation Discipline</label>
                <select id="donation_discipline" name="donation_discipline" class="form-control" v-model="searchForm.donation_discipline">
                  <option v-for="(SportDiscipline, key) in disciplines_list" :value="SportDiscipline.id" :key="'disci-'+key">{{ SportDiscipline.name }}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Donation Amount</label>
                    <input type="number" id="donation_amount" class="form-control" min="0" v-model="searchForm.donation_amount">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Transaction Id</label>
                    <input type="text" id="transaction_id" class="form-control" v-model="searchForm.transaction_id">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="transaction_start_date" class="form-label">Transaction From Date</label>
                    <div class="input-group birthdate">
                        <div class="birthdaypicker">
                            <date-pick
                                v-model="searchForm.transaction_start_date"
                                :format="'MM/DD/YYYY'"
                                :displayFormat="'MM/DD/YYYY'"
                                v-mask="'##/##/####'"
                                :parseDate="parseDatePick"
                            ></date-pick>
                        </div>
                    </div>
                    <input id="transaction_start_date" type="hidden" class="form-control" :value="transaction_start_date_1">
                    <div class="text-sm text-danger">{{ errors.transaction_start_date }}</div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="transaction_end_date" class="form-label">Transaction To Date</label>
                    <div class="input-group birthdate">
                        <div class="birthdaypicker">
                            <date-pick
                                v-model="searchForm.transaction_end_date"
                                :format="'MM/DD/YYYY'"
                                :displayFormat="'MM/DD/YYYY'"
                                v-mask="'##/##/####'"
                                :parseDate="parseDatePick"
                            ></date-pick>
                        </div>
                    </div>
                    <input id="transaction_end_date" type="hidden" class="form-control" :value="transaction_end_date_1">
                    <div class="text-sm text-danger">{{ errors.transaction_end_date }}</div>
                </div>
            </div>
            <div class="col-md-12 modal-searchbtn text-center">
                <div class="form-group">
                    <button type="submit" class="btn btn-primary btn-blue">Search</button>
                </div>
                <div class="text-sm text-danger">{{ errors.not_found }}</div>
            </div>
          </div>
        </form>
      </b-modal>
    </div>
</template>

<script>
import moment from 'moment';
import $ from "jquery";

import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";


import DatePick from "vue-date-pick";
import axios from 'axios';

const validateName = name => {
  if (name.length || name != "") {
      return { valid: true, error: null };
  }
  return { valid: false, error: null };
};

export default {
  name: "DonationReport",
  /*components: { DatePick },*/
  data(){
    return {
      exportProgress: false,
      membershipData: [],
      table: {},
      errors: {},
      disciplines_list: [],
      filtersApplied: false,
      valid: false,
      searchForm: {
        confirmation_code:'',
        full_name: '',
        transaction_start_date: '',
        transaction_end_date: '',
        membership_id: '',
        transaction_id: '',
        donation_discipline: '0',
        donation_amount: ''
      },
    };
  },
  methods: {
    loadDonationData(){
      // axios.get(this.basePath + "api/report/donations")
      //     .then(function(response) {
      //       // donationDataResp = response.data.data;
            
      //     }.bind(this))
      //     .catch(function(error) {
      //       console.log(error);
      //     });
      var self = this;
      this.table = $("#list_table").DataTable({
        sDom: "lfrtip",
        processing: true,
        serverSide: true,
        pageLength: 10,
        bFilter: true,
        // data: donationDataResp,
        rowReorder: {
          selector: 'td:nth-child(2)'
        },
        responsive: true,
        ajax: {
          url: process.env.VUE_APP_URL + "api/report/donations",
          // type: 'post',
          data: function (d) {
              d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
              d.membership_id = $('#membership_id').val() || self.searchForm.membership_id;
              d.full_name = $('#full_name').val() || self.searchForm.full_name;
              d.donation_discipline = $('#donation_discipline').val() || self.searchForm.donation_discipline;
              d.donation_amount = $('#donation_amount').val() || self.searchForm.donation_amount;
              d.transaction_id = $('#transaction_id').val() || self.searchForm.transaction_id;
              d.transaction_start_date = $("#transaction_start_date").val() || self.searchForm.transaction_start_date;
              d.transaction_end_date = $("#transaction_end_date").val() || self.searchForm.transaction_end_date;
          }
        },
        columns: [
          { data: 'member_id', name: 'member_id', orderable: true, searchable: true },
          { data: 'reg_type', name: 'reg_type', orderable: true, searchable: true },
          { data: 'name', name: 'name', orderable: true, searchable: true },
          { data: "donation_discipline", name: "donation_discipline", orderable: true, searchable: true  },
          { data: "donation_amount", name: "donation_amount", orderable: true, searchable: true, render: amount => '$'+amount  },
          { data: "transaction_id", name: "transaction_id", orderable: true, searchable: true },
          { data: "transaction_date", name: "transaction_date", orderable: true, searchable: true }
        ],
        order: [[6, 'desc']]
      });
    },
    getMembership(){
      axios.get(this.basePath + "api/getMemberships")
      .then((response) => {
        this.membershipData = response.data.data;
      })
      .catch(error=>{
        console.log("error getting Membership info")
      })
    },
    advancedSearch() {
      if(!this.validate()) {
          return;
      }
      this.table.draw();
      this.filtersApplied = true;
      this.$refs['modal-donation-filter'].hide();
    },
    validate(){
      this.errors = {}
      this.valid =  true;
      const validTStartDate = validateName(this.searchForm.transaction_start_date);
      this.errors.transaction_start_date = validTStartDate.error;

      const validTEndDate = validateName(this.searchForm.transaction_end_date);
      this.errors.transaction_end_date = validTEndDate.error;
      
      let dateValid = false;
      if(validTStartDate.valid && !this.validateDate(this.searchForm.transaction_start_date)){
          dateValid = true;
          this.errors.transaction_start_date = "Invalid Date";
      }
      if(validTEndDate.valid && !this.validateDate(this.searchForm.transaction_end_date)){
          dateValid = true;
          this.errors.transaction_end_date = "Invalid Date";
      }

      if(dateValid == false){
          if((validTStartDate.valid && validTEndDate.valid) && (moment(this.searchForm.transaction_end_date).isBefore(this.searchForm.transaction_start_date))) {
              this.errors.transaction_end_date = 'End date must be greater than Start date!';
              return this.valid =  false;
          } else {
              this.valid = true;
          }
      } else {
          this.valid = false;
      }

      return this.valid;
    },
    refreshList(event){
      this.searchForm = {
        confirmation_code:'',
        full_name: '',
        transaction_start_date: '',
        transaction_end_date: '',
        membership_id: '',
        transaction_id: '',
        donation_discipline: '0',
        donation_amount: ''
      };
      // this.table.clear().draw();
      this.table.ajax.reload();
      this.filtersApplied = false;
    },
    exportCsv(){
        var self = this;
        $.ajax({
            url: process.env.VUE_APP_URL + "api/reports/export/donations",
            type: 'post',
            "data": this.table.ajax.params(),
            beforeSend: function(){
                self.exportProgress = true;
            },
            "success": function(res, status, xhr) {
                self.exportProgress = false;
                if(res.file_url != undefined) window.open(res.file_url, '_blank');
            },
            error: function(err){
                console.log(err);
                self.exportProgress = false;
            }
        });
    }
  },
  mounted() {
    this.loadDonationData();
    this.getMembership();

    const retry = setInterval(() => {
      this.disciplines_list = this.getSiteProps('memberRegistration.individual.fields.donation.disciplines.options');
      if(this.disciplines_list) {
        this.disciplines_list = this.sortedDonations(this.disciplines_list);
        clearInterval(retry);
      }
    }, 200);
  }
}
</script>

<style scoped>

</style>